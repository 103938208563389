<template>
  <div class="Notice">
    <div class="topHeader">
      <div class="bannerTit">
        <span>竞拍公告</span>
      </div>
    </div>
    <div class="listBox">
      <div class="search">
        <div class="title">公告列表</div>
        <div class="queryParams">
          <el-input
            size="small"
            placeholder="查询拍卖标题"
            v-model="queryParams.auctionTitle"
            clearable
            @clear="handleSearch"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handleSearch"
            ></el-button>
          </el-input>
        </div>
      </div>
      <!-- <el-divider></el-divider> -->
      <div class="list" v-if="dataList.length">
        <div class="item" v-for="(item, index) in dataList" :key="index">
          <div class="imgs">
            <img :src="item.src" style="width: 100%; height: 150px" alt="" />
          </div>
          <div class="right" @click="goToDetail(item.auctionCode)">
            <div class="tit">{{ item.auctionTitle }}</div>

            <div class="desc">
              <!-- <div class="desItem">
                <span class="label">标的名称:</span>
                <span class="value">{{ item.targetName }}</span>
              </div> -->
              <div class="desItem">
                <span class="label">发布日期:</span>
                <span class="value"
                  >{{ item.releaseTime }}
                </span>
              </div>
              <div class="desItem">
                <span class="label">报名截止:</span>
                <span class="value"
                  >{{ item.dateDay }}
                  {{ item.auctionEndTime }}
                </span>
              </div>
              <div class="desItem">
                <span class="label">拍卖时间:</span>
                <span class="value"
                  >{{ item.dateDay }} {{ item.auctionStartTime }} --
                  {{ item.auctionEndTime }}</span
                >
              </div>
              <div class="desItem">
                <span class="label">起拍价格:</span>
                <span class="value" v-if="item.isPreQuotation == 'Y'"
                  >开拍前公开</span
                >
                <span class="value" v-else>
                  <span v-if="item.quoting == '1'"
                    >{{ item.startingPrice }}元 / {{ item.quotationUnit }}</span
                  >
                  <span v-else>{{ item.startingPrice }}元</span>
                </span>
              </div>
            </div>

            <!-- <div class="time">发布日期： {{ item.releaseTime }}</div> -->
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="queryParams.pageNum"
            :page-size.sync="queryParams.pageSize"
            :page-sizes="[10, 50, 100, 200]"
            layout="total, prev, pager, next,sizes, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="list" v-else>
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>

<script>
import { bidAnnouncementList } from "@/api/bidProject";
export default {
  name: "Project",

  data() {
    return {
      baseUrl: "/api",
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        auctionCode: "",
        auctionTitle: "",
      },
      total: 0,
      dataList: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      bidAnnouncementList(this.queryParams).then(({ code, rows, total }) => {
        if (code == 200) {
          this.dataList = [];
          rows.forEach((item) => {
            if (item && item.objectNames && item.objectNames.length) {
              item.src =
                this.baseUrl +
                "/file/displayResource?objectName=" +
                item.objectNames[0];
            } else {
              item.src = "";
            }
            item.releaseTime = item.releaseTime.substring(0, 10);
          });
          this.dataList = rows;
          this.total = total;
        }
      });
    },
    // 今日明日查询
    handleSearch() {
      this.queryParams.auctionCode = this.queryParams.auctionTitle;
      this.getList();
    },
    goToDetail(id) {
      this.$router.push({
        path: "/Notice/announcementDetails",
        query: {
          auctionCode: id,
        },
      });
    },
    // 当前页
    handleCurrentChange(val) {
      this.queryParams.pageNum = val;
      this.getList();
    },
    // 每页几条
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList();
    },
  },
  computed: {},
};
</script>

<style lang="less" scoped>
.el-button--success {
  background-color: #0dac65;
}

.el-button--success.is-plain {
  background-color: #fff;
  border-color: #0dac65;
  color: #0dac65;
}
.Notice {
  width: 100%;
  min-height: calc(100vh - 300px);
  .topHeader {
    width: 100%;
    height: 200px; /* 让高度自适应，以保持图片的原始纵横比 */
    background: url("../../assets/images/bidProject/jpHeader.png") center/cover
      no-repeat;
    .bannerTit {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      font-weight: 600;
      font-size: 28px;
      color: #ffffff;
      position: relative;
      span {
        position: absolute;
        bottom: 36px;
      }
    }
  }
  .listBox {
    width: 1200px;
    margin: 0 auto;
    .search {
      width: 100%;
      // height: 80px;
      padding: 40px 0;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
      .title {
        font-size: 28px;
        font-weight: 500;
        color: #000000d9;
        margin-right: 50px;
      }
    }
    .list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin: 40px 0;
      p {
        width: 100%;
        text-align: center;
        height: 200px;
        line-height: 200px;
      }
      .item {
        width: 49%;
        height: 160px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .imgs {
          width: 38%;
          height: 100%;
        }
        .right {
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          align-items: start;
          width: 60%;
          height: 85%;
          cursor: pointer;
        }
        .right:hover {
          .tit {
            color: #0dac65;
          }
        }
        .tit {
          display: -webkit-box;
          font-size: 16px;
          height: 38px;
          line-height: 20px;
          width: 100%;
          font-weight: 600;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .desc {
          .desItem {
            line-height: 1.5em;
            .label {
              color: #999;
              margin-right: 0.5em;
            }
            // .value {
            //   display: -webkit-box;
            //   height: 26px;
            //   overflow: hidden;
            //   -webkit-box-orient: vertical;
            //   -webkit-line-clamp: 2;
            // }
          }
        }

        .time {
          width: 100%;
          color: #999;
          text-align: right;
          padding-right: 2em;
        }
      }
    }
  }

  .pagination {
    width: 100%;
    // background-color: #ccc;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
